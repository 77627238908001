/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//
//# #  Create a Story Map
//#

// A story map is a canvas element that draws a basemap and uses an SVG path as
// well as points of interest to trigger DOM events or render an info box with
// descriptive text.
//
// The story map follows a progression of a path based on scroll events.
//
//
//  ## SVG structure
//
//  Your SVG for path and point of interest data should look something like this.
//
//  There may be more attributes, and it's okay if position is encoded as a mix
//  of coordinates and a transform containing a translate() directive.
//
//  The important parts are the following:
//    * a `<path />` element with the id `trail-path`, the path must be encoded in a `d` attribute
//
//    * `points` layer - each element is a `<circle />`  with a unique id, and
//      center coordinates specified with `cx` / `cy` attributes, or `cx` / `cy`
//      AND `transform="translate()"`. The labels need to be set within AI to
//      export the pretty names to `data-name`.
//
//    * a `triggers` layer - this is the same structure as the points layer,
//      except:
//        - `data-name` must connect to the data attributes in `index.html`,
//        this controls callout/popup display. This also references the
//        `data-name` in the points layer and controls highlighting.
//
//    * a `point-names` layer - each element is a `<text />` node with position
//      specified by transform="translate()". the following attributes will be interpreted:
//        - font-family: (string) Open Sans, sans-serif
//        - font-size: (float) 20.22
//        - font-weight: (integer) 700
//
//  AI may export additional attributes on text, circle and path nodes, and
//  these will be ignored along with any exported styles. For AI's export to work best, it needs the following settings:
//
//    1.) File -> Export -> Export for Screens ...
//    2.) Select map-elements artboard
//    3.) In the formats box, select 'SVG' then click the gear icon next to 'iOS, Android'
//    4.) Select 'SVG' from the menu on the left.
//    5.) Confirm the following settings
//
//           Styling: Presentation Attributes
//           Font: SVG
//           Images: Preserve
//           Object IDs: Layer Names
//           Decimal: 2
//           Minify - UNCHECKED
//           Responsive - UNCHECKED
//
//   If it appears AI is exporting `transform` attributes with `matrix` instead
//   of the expected `translate`, something will probably break
//
//
//
//

//# MOBILE STUFF

// The mobile design will maintain the map, sized to fit the top portion of the
// screen, and the user will be able to scroll through content on the bottom
// portion of the screen.

// * uses aos (aos-id `mobile-content-scroll`) manually update the map progress with `data-scroll-percent`
// * TODO: shrink the size of the scrolling container to match the height of all
// of the mobile content, so that the user reaches the end of the path by the bottom
// * TODO: fix 1px boundary on side that reveals content underneath
// * TODO: toggle events if window size changes, now mobile mode gets stuck on or off while testing in responsive mode

//# WOULD BE NICE

// -  bounding box to calculate tooltip presentation

// - create a bounding box in the svg to fit the canvas display to, and find
// some way of making sure most of that remains centered at various screen
// widths

//  - on scroll event calculate all the midpoints between the current and
//  previous location to fill in the path more cleanly

let StoryCanvas;
const _ = require('underscore');
const d3 = require('d3');
window.d3 = d3;

const ScrollPane = require('./scrollpane.js');
const TooltipOverlay = require('./tooltipoverlay.js');
const hexToRgba = require('hex-to-rgba');
require('path2d-polyfill');

const DISTANCE_THRESHOLD = 5;

// http://bl.ocks.org/syntagmatic/be8287ae3724e0c83c31273b20710496
const outerTangent = function(x1, y1, r1, x2, y2, r2) {

  const dx = x2 - x1;
  const dy = y2 - y1;
  const dist = Math.sqrt((dx*dx) + (dy*dy));

  if (dist <= Math.abs(r2-r1)) {
    return;    // no valid tangents
  }

  // Rotation from x-axis
  const angle1 = Math.atan2(dy,dx);
  const angle2 = Math.acos((r1 - r2)/dist);

  return{
    left: {
      source: {
        x: x1 + (r1 * Math.cos(angle1 + angle2)),
        y: y1 + (r1 * Math.sin(angle1 + angle2))
      },
      target: {
        x: x2 + (r2 * Math.cos(angle1 + angle2)),
        y: y2 + (r2 * Math.sin(angle1 + angle2))
      }
    },

    right: {
      source: {
        x: x1 + (r1 * Math.cos(angle1 - angle2)),
        y: y1 + (r1 * Math.sin(angle1 - angle2))
      },
      target: {
        x: x2 + (r2 * Math.cos(angle1 - angle2)),
        y: y2 + (r2 * Math.sin(angle1 - angle2))
      }
    }
  };
};

const parseTransform = function(t) {

  if (!t) {
    t = "translate(0, 0)";
  }

  t = t.replace('translate(', '')
       .replace(')', '');


  const xx = t.split(' ');

  return{
    x: parseInt(xx[0]),
    y: parseInt(xx[1])
  };
};

const simpleDistance = function(a, b) {
  const d = (Math.abs(a.x - b.x )^2) + ( Math.abs( a.y - b.y )^2 );
  return Math.sqrt(d);
};

module.exports = (StoryCanvas = class StoryCanvas {

  constructor(params) {
    this.$el = params.$el;

    this.DEBUG = false;

    // all canvas elements
    this.$canvas_el = this.$el.find('#overlay');

    // should probably dynamically create this as a child canvas
    this.data = this.$el.data();

    this.tooltip = new TooltipOverlay();

    this.mobile = false;
    // # hack
    let {
      scrollLength
    } = this.data;
    let leadTime = 500;
    let watchHeight = undefined;

    if ($(window).width() < 500) {
      // shrink_to = $('#mobile-text-navigation-content').height()
      const shrink_to = 10000;
      this.mobile = true;
      scrollLength = shrink_to;
      leadTime = 0;
      watchHeight = this.$el.find('#mobile-text-navigation-content');
    }

    console.log(this.$el.height());
    this.scroller = new ScrollPane({
      id: 'storymap',
      float: this.$el.find('canvas, #popover-wrapper, #text-navigation-content'),
      container: this.$el,
      attachmentMethod: 'fixed',
      scrollLength,
      leadTime,
      watchHeight
    });

    this.initializeCanvas();
    this.initializeEvents();

    this.cachedBaseMap = null;
  }

  popoverStates() {
    // show the last
    if (this.scroller.percent > .95) {
      this.setContentToEnd();
    }

    // show the first
    if (this.scroller.percent < .05) {
      return this.setContentToStart();
    }
  }

  setContentToStart() {
    const l = '1-bridgeport';
    $(window).trigger('map:nearest', {label: l, x: 0, y: 0});
    this.tooltip.updateContentForLabel(l);
    this.passed_labels = [l];

    $('#scroll-help').fadeIn();

    if ($('.text-navigation-section:first').visible()) {
      return true;
    }

    return $('.text-navigation-section:visible').fadeOut(400, function() {
      $("#text-navigation-content").show();
      return $('.text-navigation-section:first').fadeIn();
    });
  }

  setContentToEnd() {
    const l = '15-sheffield';
    $(window).trigger('map:nearest', {label: l, x: 0, y: 0});
    this.tooltip.updateContentForLabel(l);
    this.passed_labels = [l];

    if ($('.text-navigation-section:last').visible()) {
      return true;
    }

    return $('.text-navigation-section:visible').fadeOut(400, function() {
      $("#text-navigation-content").show();
      return $('.text-navigation-section:last').fadeIn();
    });
  }

  resetCanvas() {
    this.midpoints = [];
    this.passed_labels = [];
    this.popoverStates();
    return this.redraw();
  }

  initializeCanvas(refresh) {
    // console.log "Setting up story map"

    // Render the basemap and insert the svg
    if (refresh == null) { refresh = false; }
    this.$canvas    = this.$el.find('canvas')[0];

    this.createSVGStore();

    this.midpoints = [];
    this.passed_labels = [];

    // Load the basemap image data and then trigger the initial canvas draw

    if (refresh) {
      return this.redraw();
    } else {
      this.basemap_img = new Image();
      this.basemap_img.src = this.data.baseImage;
      return $(this.basemap_img).on('load', () => {
        if (this.svg_resp) {
          return this.redraw();
        } else {
          const that = this;
          return $.get(this.data.svg, resp => {
            that.svg_resp = resp;
            return that.redraw();
          });
        }
      });
    }
  }

  initializeEvents() {

    if (!this.mobile) {
      $(window).on('scrollpane:storymap:updated', () => {
        if (this.scroller.state === 'floating') {
          return this.redraw();
        }
      });
    } else {
      document.addEventListener('aos:in:mobile-content-scroll', evt => {
        console.log("boop");
        const p = $(evt.detail).data().scrollPercent;
        if (p) {
          this.forcePercent = p;
          return this.redraw();
        }
      });
    }

    $(window).on('scrollpane:storymap:refresh', () => {
      if (this.scroller.state === 'floating') {
        return this.resetCanvas();
      }
    });

    // Reset the canvas when pinned to the top or bottom
    $(window).on('scrollpane:storymap:pinned-top', () => {
      return this.setContentToStart();
    });

    $(window).on('scrollpane:storymap:floating', () => {
      if (!this.tooltip.visible()) {
        return this.tooltip.fadeIn();
      }
    });

    $(window).on('navigation:end-scroll', () => {
      this.resetCanvas();
      return this.setContentToStart();
    });

    $(window).on('scrollpane:storymap:pinned-bottom', () => {
      this.setContentToEnd();
      if (!this.tooltip.visible()) {
        return this.tooltip.fadeIn();
      }
    });

    $(window).on('scrollpane:storymap:exited', () => {
      this.resetCanvas();
      return this.tooltip.fadeOut();
    });

    // Handle the new nearest event, but only when scrolling.
    $(window).on('map:nearest', (event, coords) => {
      if (this.scroller.state !== 'floating') {
        return true;
      }

      this.tooltip.update(event, coords);

      if ($("#text-navigation-content").css('display')) {
        $("#text-navigation-content").fadeIn();
      }

      const next_segment = $(`[display-when-label='${coords.label}']`);
      if (next_segment.length > 0) {
        $('[display-when-label]').hide();
      }
      next_segment.show();

      $('[class-when-label]').removeClass('active');
      return $(`[class-when-label='${coords.label}']`).addClass('active');
    });

    return $(window).on('resize', () => {
      window.resized = false;
      return this.rescale();
    });
  }

  createSVGStore() {
    // Create an SVG element that will be dynamically generated
    // as calculations are made and events happen.
    //
    let container;
    if ($('#datastore').length === 0) {
      container = document.createElement("custom");
      container.setAttribute('id', 'datastore');
    } else {
      container = $('#datastore')[0];
    }

    this.store = d3.select(container);
    // For now attach it to the body so it can be observed easily.
    $('body').append(container);

    // Load the source svg data once
    return $.get(this.data.svg, resp => {
      return this.svg_resp = resp;
    });
  }

  setDimensions() {
    // Set the canvas size in pixels by the dPR and the computed CSS values of
    // the height and width.
    //
    // Returns canvas context
    //
    const _dpi = window.devicePixelRatio;

    // _h = getComputedStyle(@$el[0]).getPropertyValue("height").slice(0, -2)
    let _h = window.innerHeight;
    const _w = getComputedStyle(this.$el[0]).getPropertyValue("width").slice(0, -2);

    this.mobile = false;
    // # hack
    // TODO: positioning
    if (_w < 500) {
      this.mobile = true;
      // preserve the basemap height ratio
      const _r = this.basemap_img.height / this.basemap_img.width;
      _h = _w * _r;
    }

    //
    this._h = _h;
    this._w = _w;

    this.$canvas_el[0].setAttribute('height', _h);
    this.$canvas_el[0].setAttribute('width',  _w);

    // small displays need the _dpi fix
    if (this.mobile) {
      this.$canvas_el[0].setAttribute('height', _dpi * _h);
      this.$canvas_el[0].setAttribute('width',  _dpi * _w);
    }

    return this.ctx = this.$canvas_el[0].getContext("2d");
  }

  rescale() {

    // clearTimeout(window.resized)

    if (!window.storymap.resized) {
      window.storymap.resized = true;
      window.storymap.resizedh = setTimeout((function() {
        const mid = window.storymap.midpoints;
        window.storymap.initializeCanvas(true);
        window.storymap.midpoints = mid;
        window.storymap.redraw();
        return window.storymap.resized = false;
      }), 250);
    }

    return this.redraw();
  }

  tryScale(context) {
    // This will set the initial scale based on the size of the basemap relative
    // to the canvas width
    //
    // Will probably need to be more complex to determine whether content is
    // centered based on whether the base layer is larger than the canvas
    //
    // NB: this must occur within the context of a canvas save / restore

    let _rw;
    const w = this.$canvas.width;
    const h = this.$canvas.height;

    // Custom base values to position the canvas

    if ("asdf" === "lala") {

      // center-ish
      this.marginx = -( this.basemap_img.width ) / 4;
      // center vertically, with additions for different screen jeights
      this.marginy = Math.abs(h / 2) - (( this.basemap_img.height ) / 2.25);
      let xpadding = 500;
      let ypadding = 500;

      // Pixel-ish widths
      if ($(window).width() < 420) {
        this.marginx = -( this.basemap_img.width ) / 3.75;
        this.marginy = Math.abs(h / 2) - (( this.basemap_img.height ) / 2);
        xpadding = 475;
        ypadding = 475;
      }

      // iPhone X,
      if ($(window).width() < 395) {
        this.marginx = -( this.basemap_img.width ) / 3.75;
        this.marginy = Math.abs(h / 2) - (( this.basemap_img.height ) / 3);
        xpadding = 525;
        ypadding = 525;
      }

      if ($(window).width() < 376) {
        const _dpi = window.devicePixelRatio;
        if (_dpi === 3.0) {
          this.marginx = -( this.basemap_img.width ) / 4;
          // center vertically, with additions for different screen heights
          this.marginy = Math.abs(h / 2) - (( this.basemap_img.height ) / 2.5);
          xpadding = 500;
          ypadding = 500;
        } else {
          this.marginx = -( this.basemap_img.width ) / 3.5;
          this.marginy = Math.abs(h / 2) - (( this.basemap_img.height ) / 3.5);
          xpadding = 500;
          ypadding = 500;
        }
      }

      // for extra small displays, need to zoom a bit differently
      if ($(window).width() < 350) {
        this.marginx = -( this.basemap_img.width ) / 3.5;
        this.marginy = Math.abs(h / 2) - (( this.basemap_img.height ) / 3.5);
        xpadding = 550;
        ypadding = 550;
      }

      _rw = w / (this.basemap_img.width - xpadding);
      const _rh = h / (this.basemap_img.height - ypadding);
      context.scale(_rh, _rh);

      const shrink_to = this.$canvas_el.height();
      this.$el.find('.responsive-canvas-fix').css({
        display: "block",
        height: `${shrink_to}px`,
        width: "100%",
      });
      return context.translate(this.marginx, this.marginy);
    // desktop
    } else {
      // center horizontally
      this.marginx = (w - this.basemap_img.width) / 2;

      // center vertically
      this.marginy = Math.abs(h / 2) - (this.basemap_img.height / 2.25);

      // If the image needs to be scaled to fit the width ...
      if (w < this.basemap_img.width) {
        // Find the ratio
        _rw = w / this.basemap_img.width;
        // No margin
        this.marginx = 0;
        // Fit to the bottom to get out from under tooltips
        this.marginy = Math.abs( h - (this.basemap_img.height * _rw) );
        // Scale
        context.scale(_rw, _rw);
      }

      return context.translate(this.marginx, this.marginy);
    }
  }

  redraw() {
    this.setDimensions();

    const _canv = this.ctx;
    _canv.save();

    // Calculate the overall transform
    this.tryScale(_canv);

    // Draw the map
    this.drawOverlay(_canv);

    return _canv.restore();
  }

  cleanSvg(svg_resp) {
    // Do some processing on the svg data and return a splat
    // With the cleaned results.
    //
    const _svg = svg_resp.childNodes[0];

    const points = _svg.querySelectorAll('#points circle');
    const labels = _svg.querySelectorAll('#point-names text');
    const triggers = _svg.querySelectorAll('#triggers circle');

    // Clean the data
    //
    const cleaned_triggers = _.map(triggers, function(p) {

      const t = parseTransform(p.getAttribute('transform'));

      return {
        x: parseFloat(p.getAttribute('cx')) + t.x,
        y: parseFloat(p.getAttribute('cy')) + t.y,
        transform: p.getAttribute('transform'),
        label: p.getAttribute('data-name'),
        name: p.getAttribute('data-name'),
        radius: parseFloat(p.getAttribute('r'))
      };
    });

    const cleaned_labels = _.map(labels, function(p) {
      const t = parseTransform(p.getAttribute('transform'));

      const font = {
        fontSize: p.getAttribute('font-size'),
        fontFamily: p.getAttribute('font-family'),
        fontWeight: p.getAttribute('font-weight')
      };

      return {
        x: t.x,
        y: t.y,
        font: `${font.fontWeight} 18px ${font.fontFamily}`,
        fill: p.getAttribute('fill'),
        text: p.getAttribute('id').replace('_', ' ')
      };
    });

    const cleaned_points = _.map(points, function(p) {
      const x = parseFloat(p.getAttribute('cx'));
      const y = parseFloat(p.getAttribute('cy'));
      const transform = p.getAttribute('transform');

      const t = parseTransform(transform);

      return {
        x: x + t.x,
        y: y + t.y,
        transform: p.getAttribute('transform'),
        label: p.getAttribute('data-name') || p.getAttribute('id'),
        radius: parseFloat(p.getAttribute('r')),
      };
  });

    return{
      points: cleaned_points,
      labels: cleaned_labels,
      triggers: cleaned_triggers,
      path_node: _svg.querySelectorAll('#trail-path')[0]
    };
  }

  setupStore(data) {
    // refresh the datastore by removing all children
    $('#datastore').children().remove();

    // Prepare the POI data store.
    const poi = this.store.selectAll(".poi-nodes")
                .enter();

    const poi_labels = this.store.selectAll(".poi-labels")
                       .enter();


    return {
      poi,
      poi_labels
    };
  }


  drawOverlay(_canv) {

    let midpoint, p;
    const w = this.$canvas.width;
    const h = this.$canvas.height;

    // copy width and height
    _canv.width = w;
    _canv.height = h;

    // clear the canvas
    _canv.clearRect(0, 0, this.basemap_img.width, this.basemap_img.height);

    // TODO: this is a hardcoded position, XD output for some elements appears
    // to be relative to the frame of the page, vs. the frame of the image.
    // So, we just need to sort out the data export process.
    //
    // if @mobile
    //   # scale
    //   _canv.drawImage(@basemap_img, 0, 0, @basemap_img.width, @basemap_img.height, -18, -234, @_w, @_h)
    // else
    _canv.drawImage(this.basemap_img, 0, 0);

    if (!this.svg_resp) {
      console.log("SVG data not ready, skipping render");
      return;
    }

    const data = this.cleanSvg(this.svg_resp);
    const store = this.setupStore(data);

    // Path2D can only be created from a path string.
    //
    const trail_path = new Path2D(data.path_node.getAttribute('d'));
    const trail_transform = parseTransform(data.path_node.getAttribute('transform'));

    const length = data.path_node.getTotalLength();

    // TODO: need to also fill in @passed_labels manually, or maybe display them
    // all in advance
    if (this.mobile && this.forcePercent) {
      // take the forced percentage and calculate the midpoints up to it in
      // steps
      p = this.forcePercent;
      console.log(p);
      const step = .01;
      const ranges = _.range(0, p, step);

      // Clamp it to 0 - 1
      if (p > 1) {
        p = 1;
      }
      if (p < 0) {
        p = 0;
      }

      // create the last midpoint
      midpoint = data.path_node.getPointAtLength(length * p);
      midpoint.x += trail_transform.x;
      midpoint.y += trail_transform.y;

      // Iterate all midpoints in between
      this.midpoints = _.map(ranges, function(r) {
        midpoint = data.path_node.getPointAtLength(length * r);
        midpoint.x += trail_transform.x;
        midpoint.y += trail_transform.y;
        const rxy = {x: midpoint.x, y: midpoint.y};
        return rxy;
      });

    } else {
      // Get the midpoint, and apply the svg path transform from the trail
      // positioning to the midpoint.
      p = this.scroller.percent;

      // Clamp it to 0 - 1
      if (p > 1) {
        p = 1;
      }
      if (p < 0) {
        p = 0;
      }

      midpoint = data.path_node.getPointAtLength(length * p);
      midpoint.x += trail_transform.x;
      midpoint.y += trail_transform.y;
      this.midpoints.push({x: midpoint.x, y: midpoint.y});
    }

    // TRAIL PATH FOR DEBUG

    if (this.DEBUG) {
      _canv.save();
      _canv.beginPath();
      _canv.translate(trail_transform.x, trail_transform.y);
      _canv.strokeStyle = hexToRgba('#FFFF00');
      _canv.lineCap = "round";
      _canv.lineJoin = "round";
      _canv.lineWidth = 5;
      _canv.stroke(trail_path);
      _canv.restore();
    }

    // Use calculated midpoints to store and trace the user's path.
    //
    const line = d3.line()
      .x(d => d.x)
      .y(d => d.y);

    const user_path = new Path2D( line(this.midpoints) );

    // _canv.beginPath()
    // _canv.strokeStyle = hexToRgba '#F5E100FF'
    // _canv.lineCap = "round"
    // _canv.lineJoin = "round"
    // _canv.lineWidth = 10
    // _canv.stroke(user_path)

    // Calculate distance based on new scroll position for all points and store
    // this
    //
    store.poi.data(data.points)
       .enter()
       .append('circle')
       .attr("class", "poi-node")
       .attr("cx", p => p.x)
       .attr("cy", p => p.y)
       .attr("r",  p => p.radius)
       .attr("text", p => p.label)
       .attr("fill", "black")
       .attr("dist", pd => simpleDistance(pd, midpoint));

    store.poi.data(data.triggers)
       .enter()
       .append('circle')
       .attr("class", "poi-trigger-node")
       .attr("cx", p => p.x)
       .attr("cy", p => p.y)
       .attr("r",  p => p.radius)
       .attr("target", p => p.name)
       .attr("label", p => p.name)
       .attr("fill", "red")
       .attr("dist", pd => simpleDistance(pd, midpoint));

    // Find the nearest trigger node and update the corresponding poi-node's
    // nearest attrib. This is done by sorting by distance first, and then
    // checking if the nearest point's distance falls below a (small) threshold.
    //
    // Then, we update the nodes attributes to mark them as nearest, and only
    // when there has been a change in the last nearest point (to prevent lots
    // of refreshing)
    //
    this.store
      .selectAll(".poi-trigger-node")
      .each(function(a) { return a.dist = parseFloat(this.getAttribute('dist')); })
      .sort((a, b) => d3.ascending(a.dist, b.dist))
      .each((point, i) => {
        if ((i === 0) && (simpleDistance(midpoint, point) < DISTANCE_THRESHOLD)) {
          if (!this.lastNearest || (this.lastNearest !== point.name)) {

            // if the nearest label has not changed, no need to re-update
            // the attributes
            //
            this.store.selectAll(".poi-trigger-node")
                  .attr("nearest", false)
                  .each((pt, j) => pt.nearest = false);

            this.store.selectAll(".poi-node")
                  .attr("nearest", false)
                  .each((p, j) => p.nearest = false);

            this.store.selectAll(`.poi-trigger-node[target='${point.name}']`)
                  .attr("nearest", true)
                  .each((p, j) => p.nearest = true);

            this.store.selectAll(`.poi-node[text='${point.name}']`)
                  .attr("nearest", true)
                  .each((p, j) => p.nearest = true);

            return this.lastNearest = point.name;
          }
        }
    });

    // Render points of interest, sorting first by distance and highlighting the
    // first of them. These are probably cities, and anything that should be
    // displayed always. Text will be rendered later so it appears above.
    //
    this.store
      .selectAll(".poi-node")
      .each(function(point, i) {

        if (point.nearest) {
          _canv.lineWidth = 1;
          _canv.fillStyle = hexToRgba('#00FF00');
        } else {
          _canv.lineWidth = 1;
          _canv.fillStyle = hexToRgba('#AA6A2B');
        }

        _canv.beginPath();
        _canv.arc(point.x, point.y, point.radius, 0, 2*Math.PI);
        return _canv.fill();
    });

    // Iterate through the hidden trigger nodes, and if the cursor distance
    // falls below a certain threshold, then trigger the `map:nearest` event and
    // progressively render the trigger nodes.
    //
    this.store
      .selectAll(".poi-trigger-node")
      .each((point, i) => {
        _canv.beginPath();

        _canv.font = '12pt Open Sans';
        _canv.textAlign = 'right';
        _canv.lineWidth = 1;
        _canv.fillStyle = hexToRgba('#FF0000AA');

        if (point.nearest && (point.dist < DISTANCE_THRESHOLD)) {
          this.passed_labels.push(point.label);
          $(window).trigger(
            'map:nearest',
            {label: point.label, x: point.x, y: point.y}
          );
          _canv.font = '12pt Open Sans';
          _canv.textAlign = 'right';
          _canv.lineWidth = 1;
          _canv.fillStyle = hexToRgba('#00FF00AA');
        }

        if (Array.from(this.passed_labels).includes(point.label)) {
          this.store.selectAll(`.poi-node[text='${point.name}']`)
                .attr("nearest", true)
                .each(function(ppo, j) {
                  _canv.fillStyle = hexToRgba('#006494AA');
                  _canv.arc(ppo.x, ppo.y, 10, 0, 2*Math.PI);
                  _canv.fill();

                  _canv.fillStyle = hexToRgba('#00649499');
                  _canv.arc(ppo.x, ppo.y, 20, 0, 2*Math.PI);
                  return _canv.fill();
          });
        }

        // For debug mode, show the distance threshold as an arc
        if (this.DEBUG) {
          _canv.beginPath();
          _canv.lineWidth = 1;
          _canv.strokeStyle = hexToRgba('#FFFFFFAA');
          _canv.arc(midpoint.x, midpoint.y, DISTANCE_THRESHOLD, 0, 2*Math.PI);
          return _canv.stroke();
        }
    });

    // Render point text
    _.map(data.labels, function(label) {
      // _canv.font = label.font
      _canv.font = '500 14pt Open Sans';
      _canv.textAlign = 'left';
      _canv.lineWidth = 1;
      // _canv.fillStyle = hexToRgba '#FFFFFF'
      _canv.fillStyle = hexToRgba('#FFFFFFFF');
      return _canv.fillText(label.text, label.x, label.y);
    });


    // Save the cursor midpoint to the datastore
    store.poi.data([midpoint])
       .enter()
       .append('circle')
       .attr("class", "cursor")
       .attr("cx", p => p.x)
       .attr("cy", p => p.y);


    // Draw the cursor from the midpoint
    _canv.beginPath();
    _canv.fillStyle = hexToRgba('#F5E100FF');
    _canv.strokeStyle = hexToRgba('#F5E100FF');
    _canv.arc(midpoint.x, midpoint.y, 10, 0, 2*Math.PI);
    _canv.fill();

    // Draw the larger transparent outer cursor
    _canv.beginPath();
    _canv.fillStyle = hexToRgba('#F5E10099');
    _canv.strokeStyle = hexToRgba('#F5E10099');
    _canv.arc(midpoint.x, midpoint.y, 20, 0, 2*Math.PI);
    _canv.fill();


    // This part is annoying, because the x/y scale on the map layer is
    // different from the x/y scale within the browser viewport as a result of
    // canvas translation.
    //
    // Apart from positioning of the center of the spotlight/shadow, the math
    // for the sides follows this outer tangent implementation.
    //
    // Outer tangent:
    //   http://bl.ocks.org/syntagmatic/be8287ae3724e0c83c31273b20710496

    if (this.scroller.state === 'floating') {

      const bounds = this.tooltip.getBounds();
      const point = this.tooltip.getCenter();

      // When the window is resized, the end of the spotlight is visible and we
      // do not want to see behind the curtain
      //
      const active = this.tooltip.$el.find('img.active').visible() && !window.storymap.resized;

      if (bounds && point) {
        // Prevent any of this from being rendered when the bounds are
        // calculated to be 0, which means that the tooltip isn't rendered.
        if (bounds.top === undefined) {
          bounds.top = 1;
        }

        if ((bounds.left > 0) && (bounds.top > 0)) {

          // The center of the spotlight is half of the basemap, plus the width
          // of the circle, plus a little extra.
          //
          const center = {
            x: ( this.basemap_img.width / 2 ) + 240 + 35,
            y: (0 - this.marginy) + point.top + 64,
            r: ( bounds.height / 2 ) - 5
          };

          // better names for these
          const tangent = false;
          // outerTangent(
          //   midpoint.x,
          //   midpoint.y,
          //   5,
          //   center.x,
          //   center.y,
          //   center.r
          // )

          if (active && tangent) {
            _canv.beginPath();
            _canv.fillStyle = hexToRgba('#0064947F');
            _canv.moveTo(midpoint.x, midpoint.y);
            _canv.lineTo(tangent.left.target.x, tangent.left.target.y);
            _canv.lineTo(tangent.right.target.x, tangent.right.target.y);
            _canv.lineTo(midpoint.x, midpoint.y);
            _canv.fill();
          }

          // Debug circle
          if (this.DEBUG) {
            _canv.beginPath();
            _canv.fillStyle = hexToRgba('#006494');
            _canv.strokeStyle = hexToRgba('#006494');
            _canv.lineWidth = 2;
            _canv.arc(center.x, center.y, center.r, 0, ( 2*Math.PI ));
            _canv.fill();
            return _canv.stroke();
          }
        }
      }
    }
  }
});

// vim: set ts=2 sw=2 tw=0 syntax=coffee expandtab :
