/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
require('jquery');
require('jquery-visible');
require('jquery.scrollto');

import ScrollPane from './scrollpane.js';

export class CallToAction {

  constructor() {
    setTimeout((() => {
      return this.constructAfterDelay();
    }
    ), 500);
  }

  constructAfterDelay() {
    this.$el = $("#cta");

    const oH = this.$el.find('.single-panel').outerHeight();
    this.scroller = new ScrollPane({
      id: 'cta',
      float: $('#cta .stacked-images'),
      attachmentMethod: 'fixed',
      container: $('#cta'),
      scrollLength: oH * 4
    });

    // Using the usual jump thing doesn't work for this section because it
    // doesn't update fast enough, since it's just jumping within the section,
    // it's safe to use fixed positioning on the floating elements for this one
    // button.
    //
    this.$el.find('[data-next]').on('click', e => {
      this.scroller.setFixed();
      this.scroller.nav_jumping = true;
      return $.scrollTo('#cta-progress-1', {
        duration: 800,
        onAfter: () => {
          this.scroller.nav_jumping = false;
          return this.scroller.setFloating();
        }
      });
    });

    $(document).on('aos:in:step-0', function(evt) {
      $('[data-step=0]').css({opacity: 1});
      return $('[data-step]').not('[data-step=0]').css({opacity: 0});
    });

    $(document).on('aos:in:step-1', function(evt) {
      $('[data-step=0]').css({opacity: 1});
      $('[data-step]').not('[data-step=0]').not('[data-step=1]').css({opacity: 0});
      return $('[data-step=1]').css({opacity: 1});
    });

    $(document).on('aos:in:step-2', function(evt) {
      $('[data-step=0]').css({opacity: 1});
      $('[data-step]').not('[data-step=0]').not('[data-step=2]').css({opacity: 0});
      return $('[data-step=2]').css({opacity: 1});
    });

    $(document).on('aos:in:step-3', evt => $('[data-step]').css({opacity: 0}));

    $(window).on('navigation:begin-scroll', () => {
      this.scroller.setPinnedTop();
      const noop = function() {  };
      this.scroller.setLocked(noop);
      return this.scroller.setExited();
    });

    return $(window).on('navigation:end-scroll', (evt, opts) => {

      if (opts.label === '#cta') {
        return this.scroller.setPinnedTop();
      }
    });
  }
}

