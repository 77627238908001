/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

let ScrollPane;
module.exports = (ScrollPane = class ScrollPane {

  constructor(params) {

    const callback = function() {
    };

    this.params = params;
    this.nav_jumping = false;

    this.$floating  = params.float;
    this.$container = params.container;
    this.attachmentMethod = params.attachmentMethod || 'absolute';

    // This is a namespace thing for events triggered on `window`
    this.id = params.id || this.$container.attr('id');

    if (!this.id) {
      console.log(this.$container);
      console.log("No id specified for container, scroller will break");
    }

    this.DEBUG = false;
    if (this.id === 'cta') {
      this.DEBUG = true;
    }

    this.prefix = `scrollpane:${this.id}:`;

    this.post_scroll_callback = params.post_scroll_callback || callback;
    const scrollLength = params.scrollLength || this.$container.height();

    // value in pixels, how much scroll to not pay attention to at the beginning
    this.leadTime = params.leadTime || 0;

    if (this.leadTime > 0) {
      this.scrollLength = scrollLength + this.leadTime;
    } else {
      this.scrollLength = scrollLength;
    }

    this.originalHeight = this.$container.height();

    this.percent = 0.0;

    this.state = 'new';
    this.last_state = false;

    this.setupScroll();
    // Watch the container for height changes and adjust scroll length
    if (typeof params.watchHeight === 'object') {
      console.log("watching for height changes");
      this.last_h = this.scrollLength;

      const checkHeight = () => {
        console.log("checkHeight");
        console.log(this.last_h);
        const w = params.watchHeight[0].offsetHeight + 500;
        if (w !== this.last_h) {
          this.last_h = w;
          console.log("new height");
          console.log(this.last_h);
          this.scrollLength = this.last_h + this.leadTime;
          return this.refreshHeight(this.last_h);
        }
      };
      
      setInterval(checkHeight, 2000);
    }
  }

  refreshHeight(h) {
    this.$container.css({height: `${h}px`});
    if (this.leadTime > 0) {
      return this.$container.css({height: `${h + this.leadTime}px`});
    }
  }

  setupScroll() {
    // The canvas container will be expanded to some larger amount in order to
    // provide reasonable scroll feedback. As the user scrolls, the canvas will
    // be fixed to the viewport, and a value will be provided to StoryCanvas to
    // determine the display of a point.
    //
    this.$container.css({height: `${this.scrollLength}px`});
    if (this.leadTime > 0) {
      this.$container.css({height: `${this.scrollLength + this.leadTime}px`});
    }

    $(window).on('navigation:begin-scroll', () => {
      // When a scroll link is clicked, disable internal scroll events
      // 
      console.log('navigation:begin-scroll');
      return this.nav_jumping = true;
    });

    $(window).on('navigation:end-scroll', () => {
      // Enable events again
      //
      console.log('navigation:end-scroll');
      this.nav_jumping = false;
      this.last_state = 'scrolling';
      return this.setUnlocked();
    });

    return $(window).on('scroll', () => {
      return this.update();
    });
  }

  update() {
    // only allow events in container that is visible?
    if (this.nav_jumping) {
      return true;
    }

    if (this.$container.css('height') !== `${this.scrollLength}px`) {
      this.setUnlocked();
      this.$container.css({height:  `${this.scrollLength}px`});
    }

    let containe_y = 0;
    const pos = this.$container.position();
    if (pos) {
      containe_y = pos.top;
    }
      
    const document_y = window.scrollY;

    let zero = 0;
    const relative_position = document_y - containe_y;
    let relative_pos_with_lead = relative_position;
    let on_lead = false;

    // The user has non-zero leadtime
    if (this.leadTime > 0) {
      // If the relative position is less than the lead time, we force the
      // scroll to be at zero, otherwise subtract the amount of lead time from
      // the new scroll value.
      //
      zero -= this.leadTime;

      relative_pos_with_lead = document_y - containe_y - this.leadTime;

      if (relative_position < this.leadTime) {
        on_lead = true;
      } else {
        on_lead = false;
      }
    }


    this.scroll_max = this.scrollLength - window.innerHeight - this.leadTime;

    this.position = relative_pos_with_lead;
    this.percent  = relative_pos_with_lead / this.scroll_max;

    // if @DEBUG
    //   console.log [@state, @position, @percent, @scrollLength, @scroll_max, zero]

    if ( (this.position > zero) && (this.position < this.scroll_max) ) {
      // less than zero but greater than 0 means within lead
      if (this.position < 0) {
        this.setLeading();
      } else {
        this.setFloating();
      }
    } else if (this.position > this.scroll_max) {
      if (this.position > (this.scroll_max + window.innerHeight)) {
        this.setExited();
      } else {
        this.setPinnedBottom();
      }
    } else if (this.position < zero) {
      if (this.position < (zero - window.innerHeight)) {
        this.setExited();
      } else {
        this.setPinnedTop();
      }
    }

    return $(window).trigger( this.eventId('updated') );
  }

  eventId(id) {
    return this.prefix + id;
  }

  changeState(newstate) {
    if (newstate !== this.state) {
      this.last_state = this.state;
      this.state = newstate;
      $(window).trigger( this.eventId(newstate) );

      if (this.DEBUG) {
        return console.log("new state: " + this.state);
      }
    }
  }

  setLocked(complete) {
    if (this.state === 'locked') {
      return;
    }

    // when in the middle of scroll jumping this resize is
    // troublesome for firefox, because it will miscalculate
    // the jumps
    // @$container.css {height: "#{@originalHeight}px"}

    this.position = 0;
    this.percent = 0.0;

    this.$floating.css({
      top: "0px",
      position: "absolute"
    });

    this.changeState('locked');
    return complete();
  }

  setUnlocked() {
    return this.changeState('unlocked');
  }

  setFloating() {

    if ((this.last_state === 'locked') || (this.state === 'locked')) {
      return console.log("cannot transition to floating from locked");
    } else {
      const w = $(window).scrollTop();
      const c = this.$container.offset().top;
  
      let offs = w - c;
      if (offs < 0) {
        offs = 0;
      }
  
      if (this.attachmentMethod === 'absolute') {
        this.$floating.css({
          top: `${offs}px`,
          position: "absolute"
        });

      } else if (this.attachmentMethod === 'fixed') {
        this.$floating.css({
          top: "0px",
          position: "fixed"
        });
      }
  
      return this.changeState('floating');
    }
  }

  setFixed() {

    this.$floating.css({
      top: "0px",
      position: "fixed"
    });

    return this.changeState('fixed');
  }

  setLeading() {

    const w = $(window).scrollTop();
    const c = this.$container.offset().top;

    let offs = w - c;
    if (offs < 0) {
      offs = 0;
    }

    if (this.attachmentMethod === 'absolute') {
      this.$floating.css({
        top: `${offs}px`,
        position: "absolute"
      });

    } else if (this.attachmentMethod === 'fixed') {

      this.$floating.css({
        top: "0px",
        position: "fixed"
      });
    }

    return this.changeState('leading');
  }

  setPinnedBottom() {
    $(window).trigger( this.eventId('pinned-bottom') );

    if (this.state === 'pinned-bottom') {
      return;
    }

    let max_with_lead = this.scroll_max;
    if (this.leadTime > 0) {
      max_with_lead += this.leadTime;
    }

    this.position = this.scrollLength;
    this.percent = 1.0;
    this.$floating.css({
      top: max_with_lead,
      position: "absolute"
    });

    return this.changeState('pinned-bottom');
  }

  setExited() {
    return this.changeState('exited');
  }

  setPinnedTop() {
    if (this.state === 'pinned-top') {
      return;
    }

    this.position = 0;
    this.percent = 0.0;

    this.$floating.css({
      top: "0px",
      position: "absolute"
    });

    return this.changeState('pinned-top');
  }
});

