/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// Styles

require('normalize.css/normalize.css');
require('./styles/index.scss');

// JS includes

import Navigation from './navigation.js';
import { PreMap } from './premap.js';
import StoryCanvas from './storymap.js';
import { CallToAction } from './calltoaction.js';
import lozad from 'lozad';

// require 'jquery'
// require 'jquery-visible'
// require 'jquery.scrollto'
require('bootstrap');

import { CountUp } from 'countup.js';

// _ = require 'underscore'
// d3 = require 'd3'
// AOS = require 'aos'

AOS.init({
    duration: 1000
});

const observer = lozad();
observer.observe({
  rootMargin: '260px 0px',
  // threshold: 0.1
});

// Hack for debugging, not necessary otherwise.
window.$ = $;

$(function() {

  window.AOS = AOS;
  $.scrollTo(0);

  $(window).on('load', () => AOS.refresh());

  $(window).on('scrollpane:storymap:pinned-bottom', () => AOS.refresh());

  $(window).on('scrollpane:storymap:exited', () => AOS.refresh());

  $(window).on('scrollpane:storymap:pinned-top', () => AOS.refresh());

  $(window).on('navigation:end-scroll', () => AOS.refresh());

  window.loadPage = function() {
    $('.fadeIn').css({opacity: 1});
    $('.fadeOutLoad').css({
        opacity: 0,
        top: "25%",
    });

    const finalize = () => $('body.preload').removeClass('preload');

    const cleanup = function() {
      $('.fadeOutLoad').remove();
      $('.tiny-nav-container').css({opacity: 1});
      $('#home').css({opacity: 1});
      return $('#sidebarToggle').css({opacity: 1});
    };

    setTimeout(finalize, 250);
    return setTimeout(cleanup, 700);
  };

  $(document).ready(() => setTimeout(window.loadPage, 500));
 
  $(document).ready(function() {
    window.nav = new Navigation();

    window.storymap = new StoryCanvas({
      $el: $('[data-canvas-map]')
    });

    window.premap = new PreMap();

    //# CTA section stuff
    return window.calltoaction = new CallToAction();
  });

  return document.addEventListener('aos:in:chartin', evt => $(evt.detail).find('[data-count-up]').each(function(e) {
    // Store the value to a data attr first and use that, in case user scrolls
    // around and breaks the original text value
    let val;
    const s = $(this).html();
    if ($(this).attr('data-value')) {
      val = $(this).attr('data-value');
    } else {
      $(this).attr('data-value', s);
      val = s;
    }

    const c = parseInt(val.replace(/[$,]/g, ''));

    const opts = {};
    if (s.startsWith('$')) {
      opts.prefix = '$';
    }

    const a = new CountUp(this, c, opts);
    return a.start();
  }));
});

