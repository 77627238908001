/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// Styles

let Navigation;
require('jquery');
require('jquery-visible');
require('jquery.scrollto');

module.exports = (Navigation = class Navigation {

  constructor() {

    // Watch scroll events and occasionally update the navs
    //
    $(window).on('scroll', () => {
      if (!window.scrollUpdateH) {
        return window.scrollUpdateH = setTimeout(this.updateNavScrollPosition, 250);
      }
    });
  
    // Refresh nav state on load
    // 
    this.updateNavScrollPosition();

    // Initialize events
    // 
    this.events();
  }

  events() {
    // Sidebar display
    $('[data-toggle-sidebar]').on('click', function(event) {
        $('[data-sidebar]').toggleClass('active');
        $('[data-sidebar-overlay]').toggleClass('active');
        return event.preventDefault();
    });
  
    $('[data-sidebar-overlay]').on('click', function(event) {
        $('[data-sidebar]').toggleClass('active');
        $('[data-sidebar-overlay]').toggleClass('active');
        return event.preventDefault();
    });

    // Visual presentation on click
    $('#nav-container nav ul li a').click(function(evt) {
      $('nav ul li.nav-section.active').removeClass('active');
      return $(this).parents('li.nav-section').addClass('active');
    });

    // 
    return $('[data-scroll-to-anchor]').on('click', function(event) {
      const scroll_target = $( $(this).data().scrollToAnchor );

      if (scroll_target.length === 0) {
        event.preventDefault();
        return false;
      }

      window.scrolling = true;
      $(window).trigger('navigation:begin-scroll');

      const that = this;
      window.storymap.scroller.setLocked(() => {
        const label = $(that).data().scrollToAnchor;
        return $.scrollTo($(that).data().scrollToAnchor, {
          duration: 600,
          onAfter() {
            window.storymap.scroller.setUnlocked();

            const endScroll = function() {
              $(window).trigger('navigation:end-scroll', {label});
              return window.scrolling = false;
            };
            return setTimeout(endScroll, 250);
          }
        });
      });

      event.preventDefault();

      if ($('[data-sidebar]').hasClass('active')) {
        $('[data-sidebar]').toggleClass('active');
        return $('[data-sidebar-overlay]').toggleClass('active');
      }
    });
  }


  updateNavScrollPosition() {
    // Scrollspy won't work for this specific usecase because it relies on
    // :visible working, which isn't enough here. `jquery-visible` also works to
    // detect elements that are positioned within the viewport, but can only rely
    // on certain elements.
    //
    // It works by watching elements with `data-nav-update-scroll-anchor`, these
    // elements should already have an `id` used for nav jumping. This only
    // toggles the `active` class on the corresponding nav elements. Elements that
    // need the class toggle should specify `data-scroll-status="[ID]"`. 
    //
    // If it doesn't appear to be working for a given panel, more
    // `usable_child_elements` may need to be added.
    //

    const usable_child_elements = 'h1, h2, h3, h4, h5, p, img, button';

    const anchors = $('[data-nav-update-scroll-anchor]');
    anchors.each(function(a) {
      if ($( this ).find(usable_child_elements).visible()) {
        const update = $( this ).attr('id');
        $('[data-scroll-status]').removeClass('active');
        return $(`[data-scroll-status='${update}']`).addClass('active');
      }
    });

    try {
      return delete window.scrollUpdateH;
    } catch (e) {
      return console.log("no scrollUpdateH");
    }
  }
});


