/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
let TooltipOverlay;
const parsePx = x => parseFloat(x.replace('px', ''));

window.getViewportOffset = function($e) {
  const $window = $(window);
  const scrollLeft = $window.scrollLeft();
  const scrollTop = $window.scrollTop();
  const offset = $e.offset();
  const rect1 = { x1: scrollLeft, y1: scrollTop, x2: scrollLeft + $window.width(), y2: scrollTop + $window.height() };
  const rect2 = { x1: offset.left, y1: offset.top, x2: offset.left + $e.width(), y2: offset.top + $e.height() };
  return {
    left: offset.left - scrollLeft,
    top: offset.top - scrollTop,
    insideViewport: (rect1.x1 < rect2.x2) && (rect1.x2 > rect2.x1) && (rect1.y1 < rect2.y2) && (rect1.y2 > rect2.y1)
  };
};


module.exports = (TooltipOverlay = class TooltipOverlay {
  // Handle tooltip rendering events and display stuff coming from ScrollPane
  // and StoryMap

  constructor() {
    this.$el = $('#popover-wrapper');
    this.lastBounds = false;
    this.active = false;

    $(window).on('resize', () => {
      return this.reposition();
    });
  }

    // Listen to the scrolling panel to get rid of this thing when the user
    // scrolls away. Otherwise it scrolls with the rest of content.
    // TODO: move this over to storymap
    // $(window).on 'scrollpane:pinned-top', () =>
    //   if @active
    //     @active = false

    // $(window).on 'scrollpane:pinned-bottom', () =>
    //   if @active
    //     $('#popover-target').fadeOut().popover('dispose')
    //     $("#text-navigation-content, #image-popover").fadeOut()
    //     @active = false

  destroy() {
    $('#popover-target').fadeOut().popover('dispose');
    return $("#image-popover").fadeOut();
  }

  template(tooltip) {
    if (tooltip) {
      tooltip = tooltip.trim();
    }
    return `\
<div class="popover storymap-popover" role="tooltip">
  <div class="arrow"></div>
  <h5 class="popover-header"></h5>
  <div class="popover-body"></div>
  <div class="photo-credit">${tooltip}</div>
</div>\
`;
  }

  fadeIn() {
    return this.$el.fadeIn(200);
  }

  fadeOut() {
    return this.$el.fadeOut(200);
  }

  visible() {
    return this.$el.visible();
  }

  toggle() {
    return this.$el.css({opacity: .00001});
  }

  getBounds() {
    const r = this.$el[0];
    const e = this.$el.find('#image-popover')[0];
    const i = this.$el.find('#image-popover .image-frame')[0];
    const o = this.$el.find('img.active')[0];

    if (!i) {
      return false;
    }

    const b = i.getBoundingClientRect();

    // TODO: this isn't the precise bounds of the circle, which we still have to
    // calculate
    const l = parsePx(getComputedStyle(e).getPropertyValue("left"));
    // t = parsePx getComputedStyle(e).getPropertyValue("top")
    // h = parsePx getComputedStyle(e).getPropertyValue("height")
    // w = parsePx getComputedStyle(e).getPropertyValue("width")

    // l = getViewportOffset($(i)).left
    // b.x
    const t = b.y;
    const h = 240;
    const w = 240;

    const ww = parsePx(getComputedStyle(r).getPropertyValue("width"));

    const box = {
      left: l,
      top: t,
      height: h,
      width: w,
      container: {
        width: ww
      }
    };

    return box;
  }

  getCenter() {
    const c = $('#center-marker')[0];

    if (!c) {
      return false;
    }

    const b = c.getBoundingClientRect();

    const l = parsePx(getComputedStyle(c).getPropertyValue("left"));
    const t = parsePx(getComputedStyle(c).getPropertyValue("top"));

    const point = {
      top: this.img_top + 50,
      left: this.img_lef
    };

    return point;
  }

  reposition() {
    this.img_top = 8;
    this.img_lef = ( $(window).width() * .5 ) + 150;

    $('#image-popover').css({
      display: "block",
      position: "absolute",
      zIndex: "9",
      top: `${this.img_top}px`,
      left: `${this.img_lef}px`,
    });

    if ($('#center-marker').length === 0) {
        const deb = $('<div id="center-marker" />');
        deb.appendTo($('body'));
      }

    return $('#center-marker').css({
      display: "block",
      position: "fixed",
      zIndex: "19",
      width: "5px",
      height: "5px",
      backgroundColor: "transparent",
      top: `${this.img_top + 125}px`,
      left: `${this.img_lef + 125}px`,
    });
  }

  update(event, coords) {

    let popoverContent, popoverTitle, hasPopoverTitle;
    if (this.active) {
      popoverContent = $(`#popover-content [text-for-label='${coords.label}'] p`).text() || false;
      hasPopoverTitle = $(`#popover-content [text-for-label='${coords.label}'] h1`).length > 0;
      popoverTitle = $(`#popover-content [text-for-label='${coords.label}'] h1`).text() || false;
      this.updateContentForLabel(coords.label);
      const p = $('.storymap-popover');
      if (hasPopoverTitle) {
        p.show();
      } else {
        p.hide();
      }
        
      return;
    }
    
    // wrap in a function so that we can only trigger once and not on every
    // scroll update
    const display_popover = () => {
      // if window.tooltipInitialized?
      //   if coords.label == window.tooltipInitialized
      //     return

      // $('#popover-target').popover('dispose')

      // square
      const image_w = 250;

      let img_top = coords.y - image_w - 150;

      if (img_top < 0) {
        img_top = 10;
      }

      const img_lef = coords.x - image_w;

      // Static for now
      this.img_top = 8;
      this.img_lef = ( $(window).width() * .5 ) + 150;

      $('#popover-target').css({
        display: "block",
        position: "absolute",
        zIndex: "9",
        top: "125px",
        left: "-10px",
        width: "5px",
        height: "5px",
      });

      $('#image-popover').css({
        display: "block",
        position: "absolute",
        zIndex: "9",
        top: `${this.img_top}px`,
        left: `${this.img_lef}px`,
      });

      popoverContent = $(`#popover-content [text-for-label='${coords.label}'] p`).text() || false;
      popoverTitle = $(`#popover-content [text-for-label='${coords.label}'] h1`).text() || false;

      $('#popover-target').popover({
        content: popoverContent,
        container: "#image-popover",
        placement: "left",
        // title: coords.label
        title: popoverTitle,
        template: this.template($(`#popover-content [text-for-label='${coords.label}'] .photo-credit`).text()),
      });
      
      if ($('#center-marker').length === 0) {
          const deb = $('<div id="center-marker" />');
          deb.appendTo($('body'));
        }

      $('#center-marker').css({
        display: "block",
        position: "fixed",
        zIndex: "19",
        width: "5px",
        height: "5px",
        backgroundColor: "transparent",
        top: `${this.img_top + 125}px`,
        left: `${this.img_lef + 125}px`,
      });

      $('#popover-target').popover('show');
      this.active = true;
      
      window.tooltipInitialized = coords.label;
      return delete window._popoverHandler;
    };

    if (typeof window._popoverHandler === 'undefined') {
      window._popoverHandler = setTimeout(display_popover, 100);
      return this.lastTooltipId = coords.label;
    }
  }

  updateContentForLabel(label) {
    const content = $(`#popover-content [text-for-label='${label}'] p`).text() || undefined;
    const title = $(`#popover-content [text-for-label='${label}'] h1`).text() || undefined;
    const credit = $(`#popover-content [text-for-label='${label}'] .photo-credit`).text() || undefined;

    if(title !== undefined) {
      const p = $('.storymap-popover');

      p.find('.popover-header').html(title);
      p.find('.popover-body').html(content);
      p.find('.photo-credit').html(credit);
      return true
    } else {
      return false 
    }
  }
});


