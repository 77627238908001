/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
require('jquery');
require('jquery-visible');
require('jquery.scrollto');

import ScrollPane from './scrollpane.js';

export class PreMap {

  constructor() {
    setTimeout((() => {
      return this.constructAfterDelay();
    }
    ), 500);
  }

  constructAfterDelay() {
    this.$el = $("#pre-map");

    const oH = this.$el.find('.single-panel').outerHeight();
    this.scroller = new ScrollPane({
      id: 'pre-map',
      float: $('#pre-map .stacked-images'),
      attachmentMethod: 'fixed',
      container: $('#pre-map'),
      scrollLength: oH * 3
    });

    $(document).on('aos:in:step-0', function(evt) {
      $('[data-step=0]').css({opacity: 1});
      return $('[data-step=1]').css({opacity: 0});
    });

    $(document).on('aos:in:step-1', function(evt) {
      $('[data-step=0]').css({opacity: 0});
      return $('[data-step=1]').css({opacity: 1});
    });

    $(window).on('navigation:begin-scroll', () => {
      this.scroller.setPinnedTop();
      const noop = function() {  };
      this.scroller.setLocked(noop);
      return this.scroller.setExited();
    });

    return $(window).on('navigation:end-scroll', (evt, opts) => {

      if (opts.label === '#pre-map') {
        return this.scroller.setPinnedTop();
      }
    });
  }
}

